import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import { UqPage, UqLayoutV2, UqAppBarV2, UqHeroV2, UqNavbarV2, UqContainerV2, UqTypography, UqLinkCard, UqResourceCard, usePagination, UqPagination, UqRequestDemoBanner, UqFooterV2 } from '../components/v2';

const PAGE_SIZE = 10;
const LIST_PADDING_TOP_PX = 48;

import * as styles from './news.module.scss';

UqNewsPage.propTypes = {
  data: PropTypes.shape({
    articles: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            coverImage: PropTypes.shape({
              gatsbyImageData: PropTypes.shape(),
            }),
            description: PropTypes.shape({
              description: PropTypes.string,
            }),
            thumbnail: PropTypes.shape({
              gatsbyImageData: PropTypes.shape(),
            }),
            title: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
      ),
    }),
    spotlight: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            coverImage: PropTypes.shape({
              gatsbyImageData: PropTypes.shape(),
            }),
            description: PropTypes.shape({
              description: PropTypes.string,
            }),
            thumbnail: PropTypes.shape({
              gatsbyImageData: PropTypes.shape(),
            }),
            title: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
};

export default function UqNewsPage(props) {
  const spotlight = props.data.spotlight.edges[0];
  const articles = props.data.articles.edges;

  const cardContainerRef = useRef(null);

  const {
    activePageNumber,
    currentPage,
    goTo,
    pages,
  } = usePagination(articles, PAGE_SIZE);

  const selectPage = useCallback((pageNumber) => {
    goTo(pageNumber);

    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: cardContainerRef.current.offsetTop - LIST_PADDING_TOP_PX,
    });
  }, [goTo]);

  return (
    <UqPage
      config={{
        seo: {
          description: 'The latest news, articles, and stories about unitQ.',
          title: 'unitQ in the News',
        },
      }}
    >
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqHeroV2>
          <UqContainerV2>
            <UqTypography
              className={styles.title}
              as="h1"
            >
              !-News-!
            </UqTypography>

            <UqResourceCard
              thumbnail={spotlight.node.coverImage}
              title={spotlight.node.description.description}
              type="Latest News"
              url={spotlight.node.url}
              fill
              dark
            />
          </UqContainerV2>
        </UqHeroV2>

        <article ref={cardContainerRef}>
          <UqContainerV2
            className={styles.main}
            fill
          >
            <section className={classNames(styles.content, styles.newsGrid)}>
              {currentPage.map((v, index) => (
                <UqLinkCard
                  key={index}
                  date={v.node.publishedDate}
                  title={v.node.description.description}
                  thumbnail={v.node.coverImage}
                  url={v.node.url}
                />
              ))}
            </section>
            <section className={styles.content}>
              <UqPagination
                active={activePageNumber}
                pages={pages}
                onPageSelect={selectPage}
              />
            </section>
            <UqRequestDemoBanner />
          </UqContainerV2>
        </article>
      </UqLayoutV2>

      <UqFooterV2 />
    </UqPage>
  );
}

export const pageQuery = graphql`
 query {
    articles: allContentfulNewsArticle(sort: {fields: publishedDate, order: DESC}, skip: 1) {
      edges {
        node {
          coverImage {
            gatsbyImageData(
              formats: [AVIF, WEBP]
              placeholder: BLURRED
              width: 500
            )
            url
          }
          description {
            description
          }
          publishedDate(formatString: "MMM DD, yyyy")
          title
          url
        }
      }
    }
    spotlight: allContentfulNewsArticle(sort: {fields: publishedDate, order: DESC}, limit: 1) {
      edges {
        node {
          coverImage {
            gatsbyImageData(
              formats: [AVIF, WEBP]
              placeholder: BLURRED
              width: 800
            )
            url
          }
          description {
            description
          }
          publishedDate(formatString: "MMM DD, yyyy")
          title
          url
        }
      }
    }
  }
`;
